<template>
  <div>
    <heads></heads>
    <div class="cen">
      <div class="cen_box">
        <div class="wisdom">
          <div class="wisdom_box">
            <div class="wisdom_title">
              <img class="guoxueketang" :src="videoCate.copy" />
            </div>

            <div class="wisdom_list">
              <router-link v-for="(item, index) in videosByCateId" :key="index" :to="{
                path: '/video/videoTraining/list/detail',
                query: { id: item.id },
              }">《{{ item.name }}》</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import heads from "@components/head.vue";
import foot from "@components/foot.vue";
import { getVideoCate, getVideoList } from "@api/public";
export default {
  components: {
    heads,
    foot,
  },
  props: {},
  watch: {
    $route(to, from) {
      this.$router.go(0);
    },
  },
  data: function () {
    return {
      videosByCateId: [],
      videoCate: [],
    };
  },
  mounted: function () {
    window.onresize = () => {
      return (() => {
        this.$router.go(0);
      })();
    };
    let that = this;
    getVideoCate(that.queryWhere).then((res) => {
      that.$set(that, "videoCate", res.data);
    });
    getVideoList({ course_cate_id: that.queryWhere.id, limit: 30 }).then((res) => {
      that.$set(that, "videosByCateId", res.data.list);
    });
  },
  created: function () {
    this.backgroud = this.$route.meta.background;
    document.title = this.$route.meta.title;
    this.queryWhere = this.$route.query;
  },
};
</script>

<style scoped>
a {
  color: black;
}

a:hover {
  color: #e2cea0;
}

.cen {
  width: 100%;
  background: url(../../assets/images/newVideoTrainingDirectory.png);
  background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-position: center 0;
  padding-bottom: 50px;
}

.cen_box {
  width: 1184px;
  height: 100%;
  margin: 0 auto;
  padding-top: 200px;
}

.wisdom {
  width: 1184px;
  min-height: 300px;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding-bottom: 20px;
  border-radius: 10px;
  background: #f0ece7;
  padding-top: 50px;
}

.wisdom_box {
  width: 100%;
  height: 100%;
  background: url(../../assets/images/videoTrainingDirectoryListBg.png) no-repeat bottom;
}

.wisdom+.wisdom {
  margin-top: 25px;
}

.wisdom .wisdom_box .wisdom_title {
  margin-bottom: 40px;
}

.newTitle {
  position: absolute;
  right: 20px;
  top: -140px;
  width: 126px;
  height: 423px;
}

.wisdom .wisdom_list {
  height: auto;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.wisdom .wisdom_list a {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  box-sizing: border-box;
  text-align: center;
  font-size: 20px;
  font-family: "楷体";
  font-weight: 600;
  padding-top: 5px;
  padding-bottom: 10px;
}

.socialist>div {
  padding-left: 390px;
  width: 500px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.analysis {
  width: 100%;
  padding-top: 256px;
  display: flex;
  flex-direction: column;
}

.analysis>div {
  width: 100%;
  padding-left: 440px;
  box-sizing: border-box;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.guoxueketang {
  position: relative;
  width: 263px;
  height: 121px;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}
</style>